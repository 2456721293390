import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';

import LogoColorlessIcon from '@assets/icons/logoColorless.svg';

import urls from '@settings/urls.yaml';
import { formatRemovedDateTime } from '@utils/formatRemovedDateTime';

import { restoreUser } from '@store/auth/auth';
import registrationSlice from '@store/registration/registration';

import { icons } from '@icons/icons';
import { Button } from '@components/core-ui/Button/Button';
import { Icon } from '@components/core-ui/Icon/Icon';

import styles from './Removed.module.scss';

import { AppDispatch, RootState } from '@store/index';

const Removed = () => {
  const isTablet: boolean = useMediaQuery('(max-width:1024px)');
  const isMobile: boolean = useMediaQuery('(max-width:600px)');
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [dateTimeText, setDateTimeText] = useState('');
  const [restoreText, setRestoreText] = useState('');

  const restoreAccount = () => {
    dispatch(restoreUser());
  };

  const formatRestoreText = useCallback(() => {
    if (isMobile) {
      setDateTimeText(
        `Вы можете восстановить\nсвой аккаунт до ${formatRemovedDateTime(String(user?.updatedAt))}`,
      );
      setRestoreText(
        'Мы моментально восстановим ваши данные и вы сможете продолжить пользоваться сервисами\nнашей платформы',
      );
    } else if (isTablet) {
      setDateTimeText(
        `Вы можете восстановить свой аккаунт \nдо ${formatRemovedDateTime(String(user?.updatedAt))}`,
      );
      setRestoreText(
        'Мы моментально восстановим ваши данные\nи вы сможете продолжить пользоваться сервисами\nнашей платформы',
      );
    } else {
      setDateTimeText(
        `Вы можете восстановить свой аккаунт до ${formatRemovedDateTime(String(user?.updatedAt))}`,
      );
      setRestoreText(
        'Мы моментально восстановим ваши данные и вы сможете продолжить пользоваться сервисами нашей платформы',
      );
    }
  }, [user, isMobile, isTablet]);

  useEffect(() => {
    if (user) {
      formatRestoreText();
    }
  }, [user, navigate, formatRestoreText]);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <LogoColorlessIcon width={128} height={24} />
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>Аккаунт удален</div>

          <Link to={urls.landing.base} className={styles.close}>
            <Icon name={isTablet ? icons.closeMobile : icons.close} />
          </Link>
        </div>

        <div className={styles.list}>
          <div className={styles.body}>
            <div className={styles.icon}>
              <Icon name={icons.stop} />
            </div>

            <div className={styles.description}>
              <div className={styles.description_dateTime}>
                {dateTimeText.split('\n').map((text, i) => (
                  <span key={`dateTime_${i}`}>{text}</span>
                ))}
              </div>
              <div className={styles.description_text}>
                {restoreText.split('\n').map((text, i) => (
                  <span key={`restore_${i}`}>{text}</span>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <Button
              onClick={() => {
                dispatch(registrationSlice.actions.setIsSms(false));
                navigate('/logout');
              }}
              className={styles.buttons_goSite}
            >
              Выйти
            </Button>

            <Button onClick={restoreAccount} className={styles.buttons_restore}>
              Восстановить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Removed;
