import { ProfileCommunity } from '@store/businessCommunity/types';
import { MenuType } from '@features/Menu/types';
import { validateResourceBeforeSubmitParams, IsFormWasEditParams } from './types';

import { FIELDS } from '@utils/constants';

export const validateResourceBeforeSubmit = ({
  resource,
  setErrors,
}: validateResourceBeforeSubmitParams): boolean => {
  let hasError = false;

  if (!resource.title) {
    setErrors({
      field: 'title',
      value: 'Поле обязательно для заполнения',
    });
    hasError = true;
  }

  if (resource.title && (resource.title.length < 2 || resource.title.length > 80)) {
    setErrors({
      field: 'title',
      value: 'от 2 до 80 символов',
    });
    hasError = true;
  }

  if (
    resource.description &&
    (resource.description.length < 15 || resource.description.length > 280)
  ) {
    setErrors({
      field: FIELDS.DESCRIPTION,
      value: 'от 2 до 80 символов',
    });
    hasError = true;
  }

  if (resource.tags?.length > 20) {
    setErrors({
      field: 'tags',
      value: 'Вы можете добавить максимум 20 тегов',
    });
    hasError = true;
  }

  if (!resource.links.length) {
    setErrors({
      field: 'resourceLinks',
      value: 'Вы не можете создать блок не прикрепив материалы',
    });
    hasError = true;
  }

  return hasError;
};

export const isFormWasEdit = ({
  resource,
  isEditResource,
  editingResource,
}: IsFormWasEditParams): boolean => {
  if (isEditResource) {
    return compareResource({ resource, editingResource });
  }

  if (resource.title) return true;

  if (resource.description) return true;

  if (resource.tags.length > 0) return true;

  return resource.links.length > 0;
};

const compareResource = ({
  resource,
  editingResource,
}: Omit<IsFormWasEditParams, 'isEditResource'>): boolean => {
  if (
    resource.id !== editingResource.id ||
    resource.communityId !== editingResource.communityId ||
    resource.title !== editingResource.title ||
    resource.description !== editingResource.description
  ) {
    return true;
  }

  if (compareArrays(resource.tags, editingResource.tags)) {
    return true;
  }

  return compareArrays(resource.links, editingResource.links);
};

const compareArrays = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) {
    return true;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return true;
    }
  }

  return false;
};

export const transformCommunitiesForMenu = (
  approvedCommunities: ProfileCommunity[],
): MenuType[] => {
  return approvedCommunities.map((community) => ({
    id: community.communityId,
    iconName: '',
    title: community.title,
    hasNotification: false,
    link: `/lead/community/${community.name ? community.name : community.communityId}`,
    isBig: false,
    isCommunity: true,
    isPublic: community.isPublic,
    mainCommunityId: community.mainCommunityId,
    page: community.page,
    isActive: community.isActive,
  }));
};
