import { CommunityPage, CommunityResponse } from '@store/communityPage/types';

export interface BusinessCommunityState {
  isLoading: boolean;
  allCommunities: null;
  approvedCommunities: ProfileCommunity[];
  profileCommunities: ProfileCommunity[] | ChangeCommunity[] | null;
  businessCommunities: CommunityResponse[];
  clubCommunities: ProfileCommunity[];
  templateCommunities: null;
  removedCommunityIds: number[];
  communityErrors: Partial<CommunityErrors>;
  filteredCommunities: ProfileCommunity[];
  paidCommunities: PaidCommunity[];
}

export enum CommunityStatus {
  APPROVED = 'approved',
  ON_MODERATION = 'onModeration',
  REJECTED = 'rejected',
}

export enum CommunityType {
  CUSTOM = 'custom',
  CONNECTED = 'connected',
}

export interface ProfileCommunity {
  id: number;
  profileId: number;
  communityId: number;
  status: CommunityStatus;
  createdAt: string;
  updatedAt: string;
  communityType: CommunityType;
  title: string;
  url: string | null;
  isPublic: boolean;
  mainCommunityId?: number;
  page: CommunityPage;
  name: string | null;
  isActive?: boolean;
}

export interface ChangeCommunity {
  id?: number;
  value?: number;
  name?: string;
  title: string;
  label?: string;
  url: string;
  __isNew__?: boolean;
}

export interface FetchAllCommunitiesParams {
  page?: number;
  limit?: number;
  title?: string;
}

export interface CommunityErrors {
  default: string;
  emptyCustomName: string;
}

export interface CustomCommunity {
  title: string;
  url: string;
}

export interface CommunityFromRequest {
  id: number;
  profileId: number;
  communityId: number;
  status: CommunityStatus;
  createdAt: string;
  updatedAt: string;
}

export interface FetchBusinessCommunitiesResponse {
  data: CommunityResponse[];
  total: number;
}

export interface PaidCommunity {
  communityId: number;
  isActive: boolean;
}

export interface CreatePaidCommunitiesParams {
  paidCommunities: PaidCommunity[];
  approvedCommunities: ProfileCommunity[];
}
